import React from 'react'
import {Grid, Header, Image, Divider} from 'semantic-ui-react'

import Aydogan from "../../Assets/Team/aydogan.jpg";
import Yair from "../../Assets/Team/yair.jpg";
import Ray from "../../Assets/Team/ray.jpg"
import Serge from "../../Assets/Team/serge.jpg"
import BobC from "../../Assets/Team/bobc.png"
import Gilbert from "../../Assets/Team/gilbert.png";
import Bauke from "../../Assets/Team/bauke.png";
import Elor from "../../Assets/Team/elor.jpg"
import Todd from "../../Assets/Team/todd.png"
import Dean from "../../Assets/Team/dean.png";
import MVentures from '../../Assets/Team/mventures.png'
import MotusVentures from '../../Assets/Team/motusventures.jpeg'
import WTIVentures from '../../Assets/Team/wti.png'
import MicroVentures from '../../Assets/Team/microventures.png'
import SCCComputer from '../../Assets/Team/scccomputer.jpg'
import Koc from '../../Assets/Team/koc.png'
import Polat from '../../Assets/Team/polat.png'
import Empty from '../../Assets/Team/empty.png'

const Member = (image, name, title, showImage = true) => {
    return(
    <div style={{padding: showImage ? '7vh' : '0 7vh 7vh 7vh'}} className="centeredButton">
        {showImage && <Image centered size="small" circular src={image} />}
        <Header >
            {name}
        </Header>
        <p>{title}</p>
    </div>)
}

const Team = () => { 
    return (
    <div className="centeredButton">
        <div style={{padding: '5vh', paddingTop: '7vh'}}>
        <Header style={{fontSize: '4vh'}}>Leadership</Header>
        </div>
        <div style={{paddingTop: '1vh'}}>
            <Grid className="centeredButton">
                <Grid.Row>
                    { Member (Yair, "Yair Rivenson, PhD", "CEO / CTO / Co-Founder") }
                    { Member (Aydogan, "Aydogan Ozcan, PhD", "Co-Founder") }
                    { Member (Ray, "Raymond Kozikowski", "Chief Product Officer") }
                    { Member (Serge, "Serge Alexanian, MD", "VP of Medical Affairs, Clinical & Regulatory") }

                    { Member (Elor, "Elor Shem Tov", "Chief Administrative Officer") }
                    { Member (Todd, "Todd Parker", "Chief Commercial Officer") }
                
                </Grid.Row>
                
                <div style={{padding: '5vh', paddingTop: '2vh'}}>
                    <Header style={{fontSize: '4vh'}}>Advisory Board</Header>
                </div>
                <Grid.Row>
                { Member (Dean, "Dean Wallace, MD", "Advisor / Consultant", false) }
                </Grid.Row>
        </Grid>
        </div> 
    </div>)
}

const Board = () => {
    return (<div className="centeredButton">
    <div style={{padding: '2vh', paddingTop: '5vh'}}>
      <Header style={{fontSize: '4vh'}}>Board</Header>
    </div>
      <div style={{paddingTop: '1vh'}}>
        <Grid className="centeredButton">
            <Grid.Row>
                { Member (Yair, "Yair Rivenson, PhD", "CEO / CTO / Co-Founder") }
                { Member (Aydogan, "Aydogan Ozcan, PhD", "Co-Founder") }
                { Member (BobC, "Bob Crutchfield", "Chairman of the Board") }
                { Member (Gilbert, "Gilbert Hakim", "Board Member") }
                { Member (Bauke, "Bauke Anninga, PhD", "Board Member") }

                { Member (Polat, "Polat Sen", "Board Member") }
            </Grid.Row>
      </Grid>
    </div> 
</div>)
}


const CompanyInvestors = () => {
    return (<div className="centeredButton">
        <div style={{padding: '4vh', paddingTop: '7vh'}}>
            <Header style={{fontSize: '4vh'}}>Investors</Header>
        </div>
        <div style={{padding: '7vh'}}>
            <Grid className="centeredButton">
                <Grid.Row>
                    <div style={{padding: "4vh"}}>
                        <Image size="small" src={MVentures}/>
                    </div>
                    <div style={{padding: "4vh"}}>
                        <Image size="small" src={SCCComputer}/>
                    </div>
                    <div style={{padding: "4vh"}}>
                        <Image size="small" src={Koc}/>
                    </div>
                </Grid.Row>  
                <Grid.Row>
                    <div style={{padding: "4vh"}}>
                        <Image size="small" src={MotusVentures}/>
                    </div>
                    <div style={{padding: "4vh"}}>
                    <Image size="tiny" src={WTIVentures}/>
                    </div>
                    <div style={{padding: "4vh"}}>
                    <Image size="small" src={MicroVentures}/>
                    </div>
                </Grid.Row>
            </Grid>
        </div> 
     </div>)
}

const AboutMain = () => {
    return (
        <div style={{backgroundColor: 'whitesmoke'}}>
            <Team/>
            <Divider/>
            <Board/>
            <Divider/>
            <CompanyInvestors/>
      </div>
    )
}

export default AboutMain
